<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                                   
                        <div>
                            <input type="file" @change="handleFileChange" accept="image/*" />
                            <button @click="uploadImage">Upload Image</button>
                        </div>

                    </div>
                </div>

            </div>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Blank',
                disabled: false,
                href: '#',
                },
                {
                text: 'Blank',
                disabled: true,
                href: '#',
                },
            ],
            image: null,
            previewImage: null,
            name: null,
            address: null,
        }
    },
    created() {
        
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        initialize(){
            
            

        },       

        handleFileChange(event) {
            this.image = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(this.image);
            reader.onload = (e) => {
                this.previewImage = e.target.result;
            };
        },
        async uploadImage() {
            const formData = new FormData();
            formData.append("image", this.image);
            const response = await axios.post(`${process.env.VUE_APP_URL}/api/kmb/test/uploadImage`, formData, {
                headers: {
                "Content-Type": "multipart/form-data",
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                },
            });
            if (response.status === 200) {
                this.name = response.data.name;
                this.address = response.data.address;
            } else {
                console.error("Error uploading image");
            }
        },

    },
    watch: {

        
    }
    
}
</script>

<style scoped>



</style>